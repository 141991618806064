import { secondsToHMS } from '@invisible/common/date'
import { useContext, useMutation, useQuery } from '@invisible/trpc/client'
import { format } from '@invisible/ui/helpers'
import { adminTheme } from '@invisible/ui/mui-theme-v2'
import { BillEventMeta } from '@invisible/ultron/zod'
import AddIcon from '@mui/icons-material/Add'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import LaunchIcon from '@mui/icons-material/Launch'
import SendIcon from '@mui/icons-material/Send'
import UndoIcon from '@mui/icons-material/Undo'
import LoadingButton from '@mui/lab/LoadingButton'
import type { AlertColor } from '@mui/material/Alert'
import AppBar from '@mui/material/AppBar'
import Autocomplete from '@mui/material/Autocomplete'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Skeleton from '@mui/material/Skeleton'
import type { SnackbarCloseReason } from '@mui/material/Snackbar'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import {
  DataGridPro,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColDef,
  GridRowId,
  type GridRowSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro'
import axios from 'axios'
import { compact, first, flatten, groupBy, isEmpty, sumBy, uniq } from 'lodash'
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react'

import { MANTICORE_URL } from '../config/env'
import { BillEvents } from './BillEvents'
import { CustomSnackbar } from './common/CustomSnackbar'
import { categories } from './constants'
import { CycleSelectionMenu } from './CycleSelectionMenu'
import type { BillRowData } from './ManticoreBillsTable'

const BillDetails = ({
  open,
  billRowData,
  selectedCycleIndex,
  drafterEmail,
  onClose,
}: {
  open: boolean
  billRowData: BillRowData
  selectedCycleIndex?: number
  drafterEmail: string
  onClose: () => void
}) => {
  const reactQueryContext = useContext()
  const [selectedIndex, setSelectedIndex] = useState(selectedCycleIndex ?? 0)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [isDrafting, setIsDrafting] = useState(false)
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel | undefined>([])
  const [selectedBillEventIds, setSelectedBillEventIds] = useState<string[]>([])
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: undefined as ReactNode,
    severity: 'success' as AlertColor,
  })

  const { data: billAndTotal, isLoading: isFetchingBill } = useQuery(
    [
      'bill.findByUserAndCycle',
      {
        email: billRowData?.userEmail as string,
        startDate,
        endDate,
      },
    ],
    { enabled: open }
  )

  const { mutateAsync: preApproveForBill, isLoading: isPreApproving } = useMutation(
    'billEvent.preApproveForBill',
    {
      onError: (err) =>
        setSnackbarState((prev) => ({
          ...prev,
          open: true,
          message: err.message,
          severity: 'error',
        })),
      onSuccess: () =>
        setSnackbarState((prev) => ({
          ...prev,
          open: true,
          message: 'Successfully pre-approved bill',
          severity: 'success',
        })),
      onSettled: () => {
        reactQueryContext.invalidateQueries('bill.findByUserAndCycle')
      },
    }
  )

  const { mutateAsync: unapproveForBill, isLoading: isUnapproving } = useMutation(
    'billEvent.unapproveForBill',
    {
      onError: (err) =>
        setSnackbarState((prev) => ({
          ...prev,
          open: true,
          message: err.message,
          severity: 'error',
        })),
      onSuccess: () =>
        setSnackbarState((prev) => ({
          ...prev,
          open: true,
          message: 'Successfully unapproved bill',
          severity: 'success',
        })),
      onSettled: () => {
        reactQueryContext.invalidateQueries('bill.findByUserAndCycle')
      },
    }
  )

  const draftBill = async () => {
    await axios.post('/api/netsuite/draftBill', {
      billId: billAndTotal?.bill?.id ?? '',
      drafterEmail,
    })
  }

  const handleSnackbarClose = (_: SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') return
    setSnackbarState((prevState) => ({ ...prevState, open: false }))
  }

  const billTotal = billAndTotal?.total ?? 0
  const draftedBillEventsAmount =
    billAndTotal?.bill?.billEvents
      ?.filter(({ status }) => status === 'drafted')
      ?.reduce((acc, curr) => acc + curr.amount, 0) ?? 0
  const preApprovedBillEventsAmount =
    billAndTotal?.bill?.billEvents
      ?.filter(({ status }) => status === 'pre_approved')
      ?.reduce((acc, curr) => acc + curr.amount, 0) ?? 0
  const unapprovedBillEventsAmount =
    billAndTotal?.bill?.billEvents
      ?.filter(({ status }) => status === 'unapproved' || status === null)
      ?.reduce((acc, curr) => acc + curr.amount, 0) ?? 0
  const draftedPercentage = (draftedBillEventsAmount / billTotal) * 100
  const preApprovedPercentage = (preApprovedBillEventsAmount / billTotal) * 100
  const unapprovedPercentage = (unapprovedBillEventsAmount / billTotal) * 100

  const billEventsGroupedByProcessAndCommodity = useMemo(
    () =>
      groupBy(
        billAndTotal?.bill?.billEvents,
        (billEvent) =>
          `${billEvent.processId}-${
            billEvent?.paymentConfig?.commodity?.id ??
            billEvent?.fixedPaymentConfig?.commodity?.id ??
            billEvent?.manualCommodityId
          }-${Boolean((billEvent.meta as BillEventMeta.TSchema).isTimeTracking)}`
      ),
    [billAndTotal?.bill?.billEvents]
  )

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([])

  const handleDetailPanelExpandedRowIdsChange = useCallback((newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds)
  }, [])

  const rows = useMemo(
    () =>
      Object.values(billEventsGroupedByProcessAndCommodity).map((billEvents, i) => ({
        id: i + 1,
        process: first(billEvents)?.process?.name ?? '',
        processId: first(billEvents)?.process?.id ?? '',
        step: first(billEvents)?.stepRun?.step?.name ?? '',
        companyName: first(billEvents)?.process?.company?.name ?? '',
        paymentCommodity:
          first(billEvents)?.paymentConfig?.commodity?.name ??
          first(billEvents)?.fixedPaymentConfig?.commodity?.name ??
          first(billEvents)?.manualCommodity?.name ??
          '',
        multiplier: sumBy(billEvents, 'meta.multiplier') ?? 0,
        timeTrackingEnabled:
          first(billEvents)?.paymentConfig?.timeTrackingEnabled ??
          (first(billEvents)?.meta as BillEventMeta.TSchema)?.isTimeTracking ??
          false,
        rate:
          (first(billEvents)?.meta as BillEventMeta.TSchema)?.rate ??
          first(billEvents)?.fixedPaymentConfig?.amount ??
          0,
        total: sumBy(billEvents, 'amount') ?? 0,
        billEvents,
      })),
    [billEventsGroupedByProcessAndCommodity]
  )

  const columns = useMemo<GridColDef<typeof rows[number]>[]>(
    () => [
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      },
      {
        field: 'total',
        headerName: 'Line Item Total',
        width: 120,
        display: 'flex',
        renderCell: (params) => <div>{format.formatMillicents(params.row.total)}</div>,
        valueGetter: (_, row) => format.formatMillicents(row.total),
      },
      {
        field: 'sentToFinance',
        headerName: 'Sent to Finance',
        width: 120,
        display: 'flex',
        renderCell: (params) => {
          const billTotal = params.row.total ?? 0
          const draftedBillEventsAmount = params.row.billEvents
            ?.filter(({ status }) => status === 'drafted')
            ?.reduce((acc, curr) => acc + curr.amount, 0)
          const draftedPercentage = (draftedBillEventsAmount / billTotal) * 100

          return (
            <div>
              {format.formatMillicents(draftedBillEventsAmount)}(
              {(!isNaN(draftedPercentage) ? draftedPercentage : 0).toFixed(2)}%)
            </div>
          )
        },
        valueGetter: (_, row) => {
          const draftedBillEventsAmount = row.billEvents
            ?.filter(({ status }) => status === 'drafted')
            ?.reduce((acc, curr) => acc + curr.amount, 0)

          return format.formatMillicents(draftedBillEventsAmount)
        },
      },
      {
        field: 'readyToSend',
        headerName: 'Ready to Send',
        width: 120,
        display: 'flex',
        renderCell: (params) => {
          const billTotal = params.row.total ?? 0
          const preApprovedBillEventsAmount = params.row.billEvents
            ?.filter(({ status }) => status === 'pre_approved')
            ?.reduce((acc, curr) => acc + curr.amount, 0)
          const preApprovedPercentage = (preApprovedBillEventsAmount / billTotal) * 100

          return (
            <div>
              {format.formatMillicents(preApprovedBillEventsAmount)}(
              {(!isNaN(preApprovedPercentage) ? preApprovedPercentage : 0).toFixed(2)}%)
            </div>
          )
        },
        valueGetter: (_, row) => {
          const preApprovedBillEventsAmount = row.billEvents
            ?.filter(({ status }) => status === 'pre_approved')
            ?.reduce((acc, curr) => acc + curr.amount, 0)

          return format.formatMillicents(preApprovedBillEventsAmount)
        },
      },
      {
        field: 'unchecked',
        headerName: 'Unchecked',
        width: 120,
        display: 'flex',
        renderCell: (params) => {
          const billTotal = params.row.total ?? 0
          const unapprovedBillEventsAmount = params.row.billEvents
            ?.filter(({ status }) => status === 'unapproved' || status === null)
            ?.reduce((acc, curr) => acc + curr.amount, 0)
          const unapprovedPercentage = (unapprovedBillEventsAmount / billTotal) * 100

          return (
            <div>
              {format.formatMillicents(unapprovedBillEventsAmount)}(
              {(!isNaN(unapprovedPercentage) ? unapprovedPercentage : 0).toFixed(2)}%)
            </div>
          )
        },
        valueGetter: (_, row) => {
          const unapprovedBillEventsAmount = row.billEvents
            ?.filter(({ status }) => status === 'unapproved' || status === null)
            ?.reduce((acc, curr) => acc + curr.amount, 0)

          return format.formatMillicents(unapprovedBillEventsAmount)
        },
      },
      {
        field: 'process',
        headerName: 'Process',
        width: 200,
        display: 'flex',
        renderCell: (params) => (
          <div className='overflow-x-auto'>
            <Button
              size='small'
              component='a'
              href={`${MANTICORE_URL}/process/${params.row.processId}?view=finance`}
              target='_blank'
              rel='noreferrer'
              endIcon={<LaunchIcon />}>
              {params.row.process}
            </Button>
          </div>
        ),
      },
      {
        field: 'step',
        headerName: 'Step',
        width: 150,
      },
      {
        field: 'companyName',
        headerName: 'Company',
        width: 120,
      },
      {
        field: 'paymentCommodity',
        headerName: 'Payment Commodity',
        width: 200,
      },
      {
        field: 'multiplier',
        headerName: 'Quantity',
        width: 150,
        display: 'flex',
        renderCell: (params) => {
          const multiplier = params.row.timeTrackingEnabled
            ? secondsToHMS(params.row.multiplier / 1000)
            : params.row.multiplier

          if (params.row.timeTrackingEnabled) return <div>{multiplier}</div>

          return <div>{`${multiplier + ` ${(multiplier as number) > 1 ? 'Units' : 'Unit'}`}`}</div>
        },
        valueGetter: (_, row) => {
          const multiplier = row.timeTrackingEnabled
            ? secondsToHMS(row.multiplier / 1000)
            : row.multiplier

          if (row.timeTrackingEnabled) return multiplier

          return `${multiplier + ` ${(multiplier as number) > 1 ? 'Units' : 'Unit'}`}`
        },
      },
      {
        field: 'rate',
        headerName: 'Rate',
        width: 100,
        display: 'flex',
        renderCell: (params) => (
          <div>{`${format.formatToDollar(params.row.rate ?? 0)}${
            params.row.timeTrackingEnabled ? ' /hr' : ' /unit'
          }`}</div>
        ),
        valueGetter: (_, row) => format.formatToDollar(row.rate ?? 0),
      },
    ],
    []
  )

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      sx={{
        margin: 2,
      }}>
      <AppBar sx={{ position: 'relative' }} color='transparent'>
        <Toolbar>
          <IconButton edge='start' color='inherit' onClick={onClose} aria-label='close'>
            <CloseIcon />
          </IconButton>
          <Divider
            flexItem
            variant='middle'
            orientation='vertical'
            sx={{
              marginX: 2,
            }}
          />
          <Typography sx={{ flex: 1 }} variant='h6' component='div'>
            {`Bill #${billRowData.id}`}
          </Typography>
          <div className='flex items-center gap-1'>
            <LoadingButton
              size='small'
              startIcon={<SendIcon />}
              loading={isDrafting}
              disabled={isEmpty(billAndTotal?.bill)}
              onClick={async () => {
                setIsDrafting(true)
                await draftBill()
                  .catch(() => {
                    setSnackbarState((prev) => ({
                      ...prev,
                      open: true,
                      message: `Failed to draft bill for ${billRowData?.userName}`,
                      severity: 'error',
                    }))
                  })
                  .finally(() => {
                    setIsDrafting(false)
                  })
              }}>
              Send Bill to Finance
            </LoadingButton>
            <LoadingButton
              size='small'
              startIcon={<CheckIcon />}
              loading={isPreApproving}
              disabled={isEmpty(billAndTotal?.bill)}
              onClick={async () => {
                await preApproveForBill({
                  billId: billAndTotal?.bill?.id ?? '',
                }).catch(() => {
                  setSnackbarState((prev) => ({
                    ...prev,
                    open: true,
                    message: `Failed to pre-approve bill for ${billRowData?.userName}`,
                    severity: 'error',
                  }))
                })
              }}>
              Approve All Pending
            </LoadingButton>
            <LoadingButton
              size='small'
              color='error'
              startIcon={<UndoIcon />}
              loading={isUnapproving}
              disabled={isEmpty(billAndTotal?.bill)}
              onClick={async () => {
                const isRedrafting = billAndTotal?.bill?.billEvents.some(
                  (billEvent) => billEvent.status === 'drafted'
                )
                await unapproveForBill({
                  billId: billAndTotal?.bill?.id ?? '',
                }).catch(() => {
                  setSnackbarState((prev) => ({
                    ...prev,
                    open: true,
                    message: `Failed to unapprove bill for ${billRowData?.userName}`,
                    severity: 'error',
                  }))
                })
                if (isRedrafting) {
                  await draftBill().catch(() => {
                    setSnackbarState((prev) => ({
                      ...prev,
                      open: true,
                      message: `Failed to undraft bill for ${billRowData?.userName}`,
                      severity: 'error',
                    }))
                  })
                }
              }}>
              Unapprove Bill
            </LoadingButton>
          </div>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Stack
          direction='row'
          spacing={3}
          divider={<Divider flexItem orientation='vertical' variant='middle' />}>
          <div className='w-[400px] space-y-4 p-4'>
            <CycleSelectionMenu
              selectedIndex={selectedIndex}
              onCycleChange={(start, end, index) => {
                setStartDate(start)
                setEndDate(end)
                setSelectedIndex(index)
              }}
            />
            <div className='px-2'>
              <div className='uppercase text-gray-400'>Billing For</div>
              <div className='mt-4 flex items-center gap-1 truncate'>
                <div>
                  <Avatar alt={billRowData?.userName ?? ''} src={billRowData?.userImg ?? ''} />
                </div>
                <div>{billRowData?.userName}</div>
                <div className='text-gray-400'>{`${billRowData?.userEmail?.split('@')[0]}@`}</div>
              </div>
            </div>
            <Button
              variant='outlined'
              component='a'
              href={`${MANTICORE_URL}/profile/${billRowData?.userId}`}
              target='_blank'
              rel='noreferrer'
              endIcon={<ArrowOutwardIcon />}>
              View Profile
            </Button>
          </div>

          {isFetchingBill ? (
            <div className='p-4'>
              <Skeleton variant='text' width={300} sx={{ fontSize: '1rem' }} />
              <Skeleton variant='text' width={200} sx={{ fontSize: '3rem' }} />

              <div className='mt-7 flex h-4 w-[600px]'>
                <Skeleton variant='rectangular' width='100%' height='100%' />
              </div>
            </div>
          ) : (
            <div className='p-4'>
              <div className='py-2 uppercase text-gray-400'>Total Earned</div>
              <div className='flex text-2xl font-bold'>
                {format.formatMillicents(billAndTotal?.total ?? 0)}
              </div>

              <div className='mt-7 flex h-4 w-[600px] rounded bg-gray-200'>
                <div
                  className='bg-success-strong flex rounded-l'
                  style={{
                    width: `${Math.round(!isNaN(draftedPercentage) ? draftedPercentage : 0)}%`,
                    borderTopRightRadius:
                      preApprovedPercentage > 0 || unapprovedPercentage > 0 ? 0 : '4px',
                    borderBottomRightRadius:
                      preApprovedPercentage > 0 || unapprovedPercentage > 0 ? 0 : '4px',
                  }}
                />
                <div
                  className='bg-info-strong flex'
                  style={{
                    width: `${Math.round(
                      !isNaN(preApprovedPercentage) ? preApprovedPercentage : 0
                    )}%`,
                    borderTopRightRadius: unapprovedPercentage > 0 ? 0 : '4px',
                    borderBottomRightRadius: unapprovedPercentage > 0 ? 0 : '4px',
                    borderTopLeftRadius: draftedPercentage > 0 ? 0 : '4px',
                    borderBottomLeftRadius: draftedPercentage > 0 ? 0 : '4px',
                  }}
                />
                <div
                  className='bg-error-strong flex rounded-r'
                  style={{
                    width: `${Math.round(
                      !isNaN(unapprovedPercentage) ? unapprovedPercentage : 0
                    )}%`,
                    borderTopLeftRadius:
                      draftedPercentage > 0 || preApprovedPercentage > 0 ? 0 : '4px',
                    borderBottomLeftRadius:
                      draftedPercentage > 0 || preApprovedPercentage > 0 ? 0 : '4px',
                  }}
                />
              </div>

              <div className='mt-2 flex w-[600px] items-center justify-between'>
                <div className='space-y-1'>
                  <div className='flex items-center gap-1'>
                    <div className='bg-success-strong h-2 w-2 rounded-full' />
                    <div>Sent to Finance</div>
                  </div>
                  <div className='font-bold'>
                    {format.formatMillicents(draftedBillEventsAmount)}
                  </div>
                </div>
                <div className='space-y-1'>
                  <div className='flex items-center gap-1'>
                    <div className='bg-info-strong h-2 w-2 rounded-full' />
                    <div>Ready to Send</div>
                  </div>
                  <div className='font-bold'>
                    {format.formatMillicents(preApprovedBillEventsAmount)}
                  </div>
                </div>
                <div className='space-y-1'>
                  <div className='flex items-center gap-1'>
                    <div className='bg-error-strong h-2 w-2 rounded-full' />
                    <div>Unchecked</div>
                  </div>
                  <div className='font-bold'>
                    {format.formatMillicents(unapprovedBillEventsAmount)}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Stack>

        {isDrafting ? (
          <div className='mt-8 flex w-full justify-center text-xl font-bold'>
            <div>
              Drafting Bill # {billAndTotal?.bill?.id} for {billRowData?.userName}
            </div>
          </div>
        ) : (
          <Stack
            sx={{
              marginX: 2,
              marginTop: 4,
              boxSizing: 'border-box',
              overflow: 'auto',
            }}
            direction='column'>
            <DataGridPro
              autoHeight
              pagination
              disableRowSelectionOnClick
              loading={isFetchingBill}
              sx={{ overflow: 'auto' }}
              slots={{
                ...adminTheme.components?.MuiDataGrid?.defaultProps?.slots,
                toolbar: () => (
                  <BillDetailsCustomToolbar
                    userName={billRowData?.userName ?? ''}
                    billEvents={billAndTotal?.bill?.billEvents ?? []}
                    userId={billRowData?.userId ?? ''}
                    drafterEmail={drafterEmail}
                    billId={billAndTotal?.bill?.id ?? ''}
                    selectedBillEventIds={selectedBillEventIds}
                    setSelectedBillEventIds={setSelectedBillEventIds}
                    setRowSelectionModel={setRowSelectionModel}
                    setSnackbarState={setSnackbarState}
                  />
                ),
                detailPanelExpandIcon: KeyboardArrowDownIcon,
                detailPanelCollapseIcon: KeyboardArrowUpIcon,
              }}
              rows={rows}
              columns={columns}
              getDetailPanelContent={({ row }) => (
                <BillEvents
                  parentRowId={row.id}
                  billEvents={row.billEvents}
                  selectedBillEventIds={selectedBillEventIds}
                  setSelectedBillEventIds={setSelectedBillEventIds}
                  setSelectedBillEventParentRows={setRowSelectionModel}
                />
              )}
              getDetailPanelHeight={() => 'auto'}
              detailPanelExpandedRowIds={detailPanelExpandedRowIds}
              onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
              rowSelectionModel={rowSelectionModel}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel)
                setSelectedBillEventIds(
                  flatten(
                    compact(
                      newRowSelectionModel.map((id) => {
                        const row = rows.find((row) => row.id === id)
                        if (!row) return
                        return row.billEvents.map((billEvent) => billEvent.id)
                      })
                    )
                  )
                )
              }}
            />
          </Stack>
        )}
        {snackbarState.open ? (
          <CustomSnackbar
            open={snackbarState.open}
            message={snackbarState.message}
            severity={snackbarState.severity}
            onClose={handleSnackbarClose}
          />
        ) : null}
      </DialogContent>
    </Dialog>
  )
}

const BillDetailsCustomToolbar = ({
  userName,
  userId,
  billId,
  drafterEmail,
  selectedBillEventIds,
  setSelectedBillEventIds,
  setRowSelectionModel,
  billEvents,
  setSnackbarState,
}: {
  userName: string
  userId: string
  billId: string
  drafterEmail: string
  billEvents: { status: string | null; id: string }[]
  selectedBillEventIds?: string[]
  setSelectedBillEventIds: Dispatch<SetStateAction<string[]>>
  setRowSelectionModel: Dispatch<SetStateAction<GridRowSelectionModel | undefined>>
  setSnackbarState: Dispatch<
    SetStateAction<{
      open: boolean
      message: ReactNode
      severity: AlertColor
    }>
  >
}) => {
  const reactQueryContext = useContext()
  const [open, setOpen] = useState(false)
  const [description, setDescription] = useState('')
  const [category, setCategory] = useState('')
  const [amount, setAmount] = useState<number>()
  const [processId, setProcessId] = useState<string>('')

  const { mutateAsync: preApproveManyBillEvents, isLoading: isPreApproving } = useMutation(
    'billEvent.approveMany',
    {
      onError: (err) =>
        setSnackbarState((prev) => ({
          ...prev,
          open: true,
          message: err.message,
          severity: 'error',
        })),
      onSuccess: () =>
        setSnackbarState((prev) => ({
          ...prev,
          open: true,
          message: 'Successfully approved bill events',
          severity: 'success',
        })),
      onSettled: () => {
        setSelectedBillEventIds([])
        setRowSelectionModel([])
        reactQueryContext.invalidateQueries('bill.findByUserAndCycle')
      },
    }
  )

  const { mutateAsync: unapproveManyBillEvents, isLoading: isUnapproving } = useMutation(
    'billEvent.unapproveMany',
    {
      onError: (err) =>
        setSnackbarState((prev) => ({
          ...prev,
          open: true,
          message: err.message,
          severity: 'error',
        })),
      onSuccess: () =>
        setSnackbarState((prev) => ({
          ...prev,
          open: true,
          message: 'Successfully unapproved bill events',
          severity: 'success',
        })),
      onSettled: () => {
        setSelectedBillEventIds([])
        setRowSelectionModel([])
        reactQueryContext.invalidateQueries('bill.findByUserAndCycle')
      },
    }
  )

  const { mutateAsync: softDeleteManyBillEvents, isLoading: isSoftDeleting } = useMutation(
    'billEvent.softDeleteMany',
    {
      onError: (err) =>
        setSnackbarState((prev) => ({
          ...prev,
          open: true,
          message: err.message,
          severity: 'error',
        })),
      onSuccess: () =>
        setSnackbarState((prev) => ({
          ...prev,
          open: true,
          message: 'Successfully deleted bill events',
          severity: 'success',
        })),
      onSettled: () => {
        setSelectedBillEventIds([])
        setRowSelectionModel([])
        reactQueryContext.invalidateQueries('bill.findByUserAndCycle')
      },
    }
  )

  const { data: allProcesses, isLoading: fetchingProcesses } = useQuery(
    ['process.findMany', { status: ['active'], select: { id: true, name: true } }],
    {
      enabled: open,
    }
  )

  const { mutateAsync: createManualBillEvent, isLoading: isCreatingManualBillEvent } = useMutation(
    'billEvent.createManual',
    {
      onSuccess: async () => {
        setSnackbarState((prev) => ({
          ...prev,
          open: true,
          message: `Line item created for ${userName}`,
          severity: 'success',
        }))
      },
      onError: (err) =>
        setSnackbarState((prev) => ({
          ...prev,
          open: true,
          message: err.message,
          severity: 'error',
        })),
      onSettled: () => {
        reactQueryContext.invalidateQueries('bill.findByUserAndCycle')
      },
    }
  )

  const handleCreateManualBillEvent = async () => {
    await createManualBillEvent({
      userId: userId,
      billId: billId,
      processId: processId,
      customRate: amount ?? 0,
      description,
      category,
      isTimeBased: false,
    })
    setAmount(undefined)
    setDescription('')
    setCategory('')
    setProcessId('')
    setOpen(false)
  }

  return (
    <GridToolbarContainer>
      <div className='flex items-center gap-2'>
        <LoadingButton
          size='small'
          startIcon={<CheckIcon />}
          loading={isPreApproving}
          disabled={isEmpty(selectedBillEventIds)}
          onClick={async () => {
            await preApproveManyBillEvents({
              billEventIds: uniq(compact(selectedBillEventIds)),
            }).catch(() => {
              setSnackbarState((prev) => ({
                ...prev,
                open: true,
                message: `Failed to pre-approve bill events for ${userName}`,
                severity: 'error',
              }))
            })
          }}>
          Approve
        </LoadingButton>
        <LoadingButton
          size='small'
          startIcon={<UndoIcon />}
          loading={isUnapproving}
          disabled={isEmpty(selectedBillEventIds)}
          onClick={async () => {
            const billEventsToUnapprove = billEvents.filter((be) =>
              selectedBillEventIds?.includes(be.id)
            )
            const isRedrafting = billEventsToUnapprove?.some(
              (billEvent) => billEvent.status === 'drafted'
            )
            await unapproveManyBillEvents({
              billEventIds: uniq(compact(selectedBillEventIds)),
            }).catch(() => {
              setSnackbarState((prev) => ({
                ...prev,
                open: true,
                message: `Failed to unapprove bill events for ${userName}`,
                severity: 'error',
              }))
            })

            if (isRedrafting) {
              await axios
                .post('/api/netsuite/draftBill', {
                  billId,
                  drafterEmail,
                })
                .catch(() => {
                  setSnackbarState((prev) => ({
                    ...prev,
                    open: true,
                    message: `Failed to undraft bill for ${userName}`,
                    severity: 'error',
                  }))
                })
            }
          }}>
          Unapprove
        </LoadingButton>
        <LoadingButton
          size='small'
          startIcon={<DeleteIcon />}
          disabled={isEmpty(selectedBillEventIds)}
          loading={isSoftDeleting}
          onClick={async () => {
            await softDeleteManyBillEvents({
              ids: uniq(compact(selectedBillEventIds)),
              deleteReason: 'Manually deleted from bill details',
            })
          }}>
          Delete
        </LoadingButton>
        <Divider flexItem variant='middle' orientation='vertical' />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        <Divider flexItem variant='middle' orientation='vertical' />
        <Button
          size='small'
          startIcon={<AddIcon />}
          disabled={isEmpty(billId) || isEmpty(userId)}
          onClick={() => setOpen(true)}>
          Create Line Item
        </Button>
        <Dialog fullWidth maxWidth='sm' open={open} onClose={() => setOpen(false)}>
          <DialogTitle>Create Line Item</DialogTitle>
          <DialogContent>
            <Stack direction='column' spacing={2} marginTop={1}>
              <TextField
                fullWidth
                required
                label='Amount'
                size='small'
                type='number'
                inputProps={{
                  inputMode: 'decimal',
                  pattern: '[0-9]*',
                }}
                InputProps={{
                  startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                }}
                value={amount}
                onChange={(e) => setAmount(Number(e.target.value))}
              />
              <TextField
                fullWidth
                required
                multiline
                label='Description'
                size='small'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <FormControl required fullWidth size='small'>
                <InputLabel id='category-select-label'>Category</InputLabel>
                <Select
                  labelId='category-select-label'
                  id='category-select'
                  label='Category'
                  value={category}
                  onChange={(e) => setCategory(e.target.value as string)}>
                  {categories.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Autocomplete
                autoHighlight
                clearOnEscape
                fullWidth
                size='small'
                disabled={fetchingProcesses}
                options={(allProcesses ?? [])
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  ?.map(({ id }) => id)}
                renderInput={(params) => (
                  <TextField required {...params} label='Process' size='small' />
                )}
                value={processId}
                onChange={(_, newValue) => {
                  if (!newValue) return
                  setProcessId(newValue)
                }}
                renderOption={(props, option) => (
                  <li {...props}>{allProcesses?.find(({ id }) => id === option)?.name ?? ''}</li>
                )}
                getOptionLabel={(option) =>
                  allProcesses?.find(({ id }) => id === option)?.name ?? ''
                }
              />
            </Stack>

            <Stack direction='row' justifyContent='space-between' marginTop={2}>
              <Button sx={{ textTransform: 'uppercase' }} onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <LoadingButton
                variant='contained'
                sx={{ textTransform: 'uppercase' }}
                loading={isCreatingManualBillEvent}
                onClick={handleCreateManualBillEvent}>
                Add
              </LoadingButton>
            </Stack>
          </DialogContent>
        </Dialog>
      </div>
    </GridToolbarContainer>
  )
}

export { BillDetails }
